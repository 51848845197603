.markdown-blog {
  & img {
    width: 100%;
    height: 400px;
    object-fit: contain !important;
  }
}
.markdown-v2 {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  color: #24292f;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}

.markdown-v2 .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}

.markdown-v2 h1:hover .anchor .octicon-link:before,
.markdown-v2 h2:hover .anchor .octicon-link:before,
.markdown-v2 h3:hover .anchor .octicon-link:before,
.markdown-v2 h4:hover .anchor .octicon-link:before,
.markdown-v2 h5:hover .anchor .octicon-link:before,
.markdown-v2 h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: " ";
  display: inline-block;
  background-color: currentColor;
  -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
  mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
}

.markdown-v2 details,
.markdown-v2 figcaption,
.markdown-v2 figure {
  display: block;
}

.markdown-v2 summary {
  display: list-item;
}

.markdown-v2 [hidden] {
  display: none !important;
}

.markdown-v2 a {
  background-color: transparent;
  color: #0969da;
  text-decoration: none;
}

.markdown-v2 abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

.markdown-v2 b,
.markdown-v2 strong {
  font-weight: 600;
}

.markdown-v2 dfn {
  font-style: italic;
}

.markdown-v2 h1 {
  margin: 0.67em 0;
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid hsla(210, 18%, 87%, 1);
}

.markdown-v2 mark {
  background-color: #fff8c5;
  color: #24292f;
}

.markdown-v2 small {
  font-size: 90%;
}

.markdown-v2 sub,
.markdown-v2 sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.markdown-v2 sub {
  bottom: -0.25em;
}

.markdown-v2 sup {
  top: -0.5em;
}

.markdown-v2 img {
  border-style: none;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  box-sizing: content-box;
  background-color: #ffffff;
}

.markdown-v2 code,
.markdown-v2 kbd,
.markdown-v2 pre,
.markdown-v2 samp {
  font-family: monospace;
  font-size: 1em;
}

.markdown-v2 figure {
  margin: 1em 40px;
}

.markdown-v2 hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border-bottom: 1px solid hsla(210, 18%, 87%, 1);
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #d0d7de;
  border: 0;
}

.markdown-v2 input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.markdown-v2 [type="button"],
.markdown-v2 [type="reset"],
.markdown-v2 [type="submit"] {
  -webkit-appearance: button;
}

.markdown-v2 [type="checkbox"],
.markdown-v2 [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.markdown-v2 [type="number"]::-webkit-inner-spin-button,
.markdown-v2 [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.markdown-v2 [type="search"]::-webkit-search-cancel-button,
.markdown-v2 [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.markdown-v2 ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

.markdown-v2 ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.markdown-v2 a:hover {
  text-decoration: underline;
}

.markdown-v2 ::placeholder {
  color: #6e7781;
  opacity: 1;
}

.markdown-v2 hr::before {
  display: table;
  content: "";
}

.markdown-v2 hr::after {
  display: table;
  clear: both;
  content: "";
}

.markdown-v2 table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}

.markdown-v2 td,
.markdown-v2 th {
  padding: 0;
}

.markdown-v2 details summary {
  cursor: pointer;
}

.markdown-v2 details:not([open]) > *:not(summary) {
  display: none !important;
}

.markdown-v2 a:focus,
.markdown-v2 [role="button"]:focus,
.markdown-v2 input[type="radio"]:focus,
.markdown-v2 input[type="checkbox"]:focus {
  outline: 2px solid #0969da;
  outline-offset: -2px;
  box-shadow: none;
}

.markdown-v2 a:focus:not(:focus-visible),
.markdown-v2 [role="button"]:focus:not(:focus-visible),
.markdown-v2 input[type="radio"]:focus:not(:focus-visible),
.markdown-v2 input[type="checkbox"]:focus:not(:focus-visible) {
  outline: solid 1px transparent;
}

.markdown-v2 a:focus-visible,
.markdown-v2 [role="button"]:focus-visible,
.markdown-v2 input[type="radio"]:focus-visible,
.markdown-v2 input[type="checkbox"]:focus-visible {
  outline: 2px solid #0969da;
  outline-offset: -2px;
  box-shadow: none;
}

.markdown-v2 a:not([class]):focus,
.markdown-v2 a:not([class]):focus-visible,
.markdown-v2 input[type="radio"]:focus,
.markdown-v2 input[type="radio"]:focus-visible,
.markdown-v2 input[type="checkbox"]:focus,
.markdown-v2 input[type="checkbox"]:focus-visible {
  outline-offset: 0;
}

.markdown-v2 kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  line-height: 10px;
  color: #24292f;
  vertical-align: middle;
  background-color: #f6f8fa;
  border: solid 1px rgba(175, 184, 193, 0.2);
  border-bottom-color: rgba(175, 184, 193, 0.2);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 rgba(175, 184, 193, 0.2);
}

.markdown-v2 h1,
.markdown-v2 h2,
.markdown-v2 h3,
.markdown-v2 h4,
.markdown-v2 h5,
.markdown-v2 h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  scroll-margin-top: 160px;
}

.markdown-v2 h2 {
  font-weight: 700;
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid hsla(210, 18%, 87%, 1);
}

.markdown-v2 h3 {
  font-weight: 600;
  font-size: 1.25em;
}

.markdown-v2 h4 {
  font-weight: 600;
  font-size: 1em;
}

.markdown-v2 h5 {
  font-weight: 600;
  font-size: 0.875em;
}

.markdown-v2 h6 {
  font-weight: 600;
  font-size: 0.85em;
  color: #57606a;
}

.markdown-v2 p {
  margin-top: 0;
  margin-bottom: 10px;
}

.markdown-v2 blockquote {
  border: 0 !important;

  line-height: 1.5;
  font-size: 1.5em;
  margin: 40px auto !important;
  position: relative;
  font-family: Georgia, serif;
  padding: 24px 24px 24px 64px !important;
  border-radius: 16px;
  background-color: rgb(244, 246, 248);
  color: rgb(99, 115, 129) !important;
  &::before {
    left: 16px;
    top: -8px;
    display: block;
    font-size: 3em;
    content: "“";
    position: absolute;
    color: rgb(145, 158, 171);
  }
}

.markdown-v2 ul,
.markdown-v2 ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.markdown-v2 ol ol,
.markdown-v2 ul ol {
  list-style-type: lower-roman;
}

.markdown-v2 ul ul ol,
.markdown-v2 ul ol ol,
.markdown-v2 ol ul ol,
.markdown-v2 ol ol ol {
  list-style-type: lower-alpha;
}

.markdown-v2 dd {
  margin-left: 0;
}

.markdown-v2 tt,
.markdown-v2 code,
.markdown-v2 samp {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 12px;
}

.markdown-v2 pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
    Liberation Mono, monospace;
  font-size: 12px;
  word-wrap: normal;
}

.markdown-v2 .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}

.markdown-v2 input::-webkit-outer-spin-button,
.markdown-v2 input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.markdown-v2::before {
  display: table;
  content: "";
}

.markdown-v2::after {
  display: table;
  clear: both;
  content: "";
}

.markdown-v2 > *:first-child {
  margin-top: 0 !important;
}

.markdown-v2 > *:last-child {
  margin-bottom: 0 !important;
}

.markdown-v2 a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.markdown-v2 .absent {
  color: #cf222e;
}

.markdown-v2 .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}

.markdown-v2 .anchor:focus {
  outline: none;
}

.markdown-v2 p,
.markdown-v2 blockquote,
.markdown-v2 ul,
.markdown-v2 ol,
.markdown-v2 dl,
.markdown-v2 table,
.markdown-v2 pre,
.markdown-v2 details {
  margin-top: 0;
  margin-bottom: 24px;
}

.markdown-v2 blockquote > :first-child {
  margin-top: 0;
}

.markdown-v2 blockquote > :last-child {
  margin-bottom: 0;
}

.markdown-v2 h1 .octicon-link,
.markdown-v2 h2 .octicon-link,
.markdown-v2 h3 .octicon-link,
.markdown-v2 h4 .octicon-link,
.markdown-v2 h5 .octicon-link,
.markdown-v2 h6 .octicon-link {
  color: #24292f;
  vertical-align: middle;
  visibility: hidden;
}

.markdown-v2 h1:hover .anchor,
.markdown-v2 h2:hover .anchor,
.markdown-v2 h3:hover .anchor,
.markdown-v2 h4:hover .anchor,
.markdown-v2 h5:hover .anchor,
.markdown-v2 h6:hover .anchor {
  text-decoration: none;
}

.markdown-v2 h1:hover .anchor .octicon-link,
.markdown-v2 h2:hover .anchor .octicon-link,
.markdown-v2 h3:hover .anchor .octicon-link,
.markdown-v2 h4:hover .anchor .octicon-link,
.markdown-v2 h5:hover .anchor .octicon-link,
.markdown-v2 h6:hover .anchor .octicon-link {
  visibility: visible;
}

.markdown-v2 h1 tt,
.markdown-v2 h1 code,
.markdown-v2 h2 tt,
.markdown-v2 h2 code,
.markdown-v2 h3 tt,
.markdown-v2 h3 code,
.markdown-v2 h4 tt,
.markdown-v2 h4 code,
.markdown-v2 h5 tt,
.markdown-v2 h5 code,
.markdown-v2 h6 tt,
.markdown-v2 h6 code {
  padding: 0 0.2em;
  font-size: inherit;
}

.markdown-v2 summary h1,
.markdown-v2 summary h2,
.markdown-v2 summary h3,
.markdown-v2 summary h4,
.markdown-v2 summary h5,
.markdown-v2 summary h6 {
  display: inline-block;
}

.markdown-v2 summary h1 .anchor,
.markdown-v2 summary h2 .anchor,
.markdown-v2 summary h3 .anchor,
.markdown-v2 summary h4 .anchor,
.markdown-v2 summary h5 .anchor,
.markdown-v2 summary h6 .anchor {
  margin-left: -40px;
}

.markdown-v2 summary h1,
.markdown-v2 summary h2 {
  padding-bottom: 0;
  border-bottom: 0;
}

.markdown-v2 ul.no-list,
.markdown-v2 ol.no-list {
  padding: 0;
  list-style-type: none;
}

.markdown-v2 ol[type="a"] {
  list-style-type: lower-alpha;
}

.markdown-v2 ol[type="A"] {
  list-style-type: upper-alpha;
}

.markdown-v2 ol[type="i"] {
  list-style-type: lower-roman;
}

.markdown-v2 ol[type="I"] {
  list-style-type: upper-roman;
}

.markdown-v2 ol[type="1"] {
  list-style-type: decimal;
}

.markdown-v2 div > ol:not([type]) {
  list-style-type: decimal;
}

.markdown-v2 ul ul,
.markdown-v2 ul ol,
.markdown-v2 ol ol,
.markdown-v2 ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-v2 li > p {
  margin-top: 16px;
}

.markdown-v2 li + li {
  margin-top: 0.25em;
}

.markdown-v2 dl {
  padding: 0;
}

.markdown-v2 dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.markdown-v2 dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.markdown-v2 table th {
  font-weight: 600;
}

.markdown-v2 table th,
.markdown-v2 table td {
  padding: 6px 13px;
  border: 1px solid #d0d7de;
}

.markdown-v2 table tr {
  background-color: #ffffff;
  border-top: 1px solid hsla(210, 18%, 87%, 1);
}

.markdown-v2 table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.markdown-v2 table img {
  background-color: transparent;
}

.markdown-v2 img[align="right"] {
  padding-left: 20px;
}

.markdown-v2 img[align="left"] {
  padding-right: 20px;
}

.markdown-v2 .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent;
}

.markdown-v2 span.frame {
  display: block;
  overflow: hidden;
}

.markdown-v2 span.frame > span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid #d0d7de;
}

.markdown-v2 span.frame span img {
  display: block;
  float: left;
}

.markdown-v2 span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: #24292f;
}

.markdown-v2 span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown-v2 span.align-center > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}

.markdown-v2 span.align-center span img {
  margin: 0 auto;
  text-align: center;
}

.markdown-v2 span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}

.markdown-v2 span.align-right > span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}

.markdown-v2 span.align-right span img {
  margin: 0;
  text-align: right;
}

.markdown-v2 span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}

.markdown-v2 span.float-left span {
  margin: 13px 0 0;
}

.markdown-v2 span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}

.markdown-v2 span.float-right > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}

.markdown-v2 code,
.markdown-v2 tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: rgba(175, 184, 193, 0.2);
  border-radius: 6px;
}

.markdown-v2 code br,
.markdown-v2 tt br {
  display: none;
}

.markdown-v2 del code {
  text-decoration: inherit;
}

.markdown-v2 samp {
  font-size: 85%;
}

.markdown-v2 pre code {
  font-size: 100%;
}

.markdown-v2 pre > code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.markdown-v2 .highlight {
  margin-bottom: 16px;
}

.markdown-v2 .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.markdown-v2 .highlight pre,
.markdown-v2 pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
}

.markdown-v2 pre code,
.markdown-v2 pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

.markdown-v2 .csv-data td,
.markdown-v2 .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}

.markdown-v2 .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: #ffffff;
  border: 0;
}

.markdown-v2 .csv-data tr {
  border-top: 0;
}

.markdown-v2 .csv-data th {
  font-weight: 600;
  background: #f6f8fa;
  border-top: 0;
}

.markdown-v2 [data-footnote-ref]::before {
  content: "[";
}

.markdown-v2 [data-footnote-ref]::after {
  content: "]";
}

.markdown-v2 .footnotes {
  font-size: 12px;
  color: #57606a;
  border-top: 1px solid #d0d7de;
}

.markdown-v2 .footnotes ol {
  padding-left: 16px;
}

.markdown-v2 .footnotes ol ul {
  display: inline-block;
  padding-left: 16px;
  margin-top: 16px;
}

.markdown-v2 .footnotes li {
  position: relative;
}

.markdown-v2 .footnotes li:target::before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: "";
  border: 2px solid #0969da;
  border-radius: 6px;
}

.markdown-v2 .footnotes li:target {
  color: #24292f;
}

.markdown-v2 .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}

.markdown-v2 .pl-c {
  color: #6e7781;
}

.markdown-v2 .pl-c1,
.markdown-v2 .pl-s .pl-v {
  color: #0550ae;
}

.markdown-v2 .pl-e,
.markdown-v2 .pl-en {
  color: #8250df;
}

.markdown-v2 .pl-smi,
.markdown-v2 .pl-s .pl-s1 {
  color: #24292f;
}

.markdown-v2 .pl-ent {
  color: #116329;
}

.markdown-v2 .pl-k {
  color: #cf222e;
}

.markdown-v2 .pl-s,
.markdown-v2 .pl-pds,
.markdown-v2 .pl-s .pl-pse .pl-s1,
.markdown-v2 .pl-sr,
.markdown-v2 .pl-sr .pl-cce,
.markdown-v2 .pl-sr .pl-sre,
.markdown-v2 .pl-sr .pl-sra {
  color: #0a3069;
}

.markdown-v2 .pl-v,
.markdown-v2 .pl-smw {
  color: #953800;
}

.markdown-v2 .pl-bu {
  color: #82071e;
}

.markdown-v2 .pl-ii {
  color: #f6f8fa;
  background-color: #82071e;
}

.markdown-v2 .pl-c2 {
  color: #f6f8fa;
  background-color: #cf222e;
}

.markdown-v2 .pl-sr .pl-cce {
  font-weight: bold;
  color: #116329;
}

.markdown-v2 .pl-ml {
  color: #3b2300;
}

.markdown-v2 .pl-mh,
.markdown-v2 .pl-mh .pl-en,
.markdown-v2 .pl-ms {
  font-weight: bold;
  color: #0550ae;
}

.markdown-v2 .pl-mi {
  font-style: italic;
  color: #24292f;
}

.markdown-v2 .pl-mb {
  font-weight: bold;
  color: #24292f;
}

.markdown-v2 .pl-md {
  color: #82071e;
  background-color: #ffebe9;
}

.markdown-v2 .pl-mi1 {
  color: #116329;
  background-color: #dafbe1;
}

.markdown-v2 .pl-mc {
  color: #953800;
  background-color: #ffd8b5;
}

.markdown-v2 .pl-mi2 {
  color: #eaeef2;
  background-color: #0550ae;
}

.markdown-v2 .pl-mdr {
  font-weight: bold;
  color: #8250df;
}

.markdown-v2 .pl-ba {
  color: #57606a;
}

.markdown-v2 .pl-sg {
  color: #8c959f;
}

.markdown-v2 .pl-corl {
  text-decoration: underline;
  color: #0a3069;
}

.markdown-v2 g-emoji {
  display: inline-block;
  min-width: 1ch;
  font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1em;
  font-style: normal !important;
  font-weight: 400;
  line-height: 1;
  vertical-align: -0.075em;
}

.markdown-v2 g-emoji img {
  width: 1em;
  height: 1em;
}

.markdown-v2 .task-list-item {
  list-style-type: none;
}

.markdown-v2 .task-list-item label {
  font-weight: 400;
}

.markdown-v2 .task-list-item.enabled label {
  cursor: pointer;
}

.markdown-v2 .task-list-item + .task-list-item {
  margin-top: 4px;
}

.markdown-v2 .task-list-item .handle {
  display: none;
}

.markdown-v2 .task-list-item-checkbox {
  margin: 0 0.2em 0.25em -1.4em;
  vertical-align: middle;
}

.markdown-v2 .contains-task-list:dir(rtl) .task-list-item-checkbox {
  margin: 0 -1.6em 0.25em 0.2em;
}

.markdown-v2 .contains-task-list {
  position: relative;
}

.markdown-v2 .contains-task-list:hover .task-list-item-convert-container,
.markdown-v2
  .contains-task-list:focus-within
  .task-list-item-convert-container {
  display: block;
  width: auto;
  height: 24px;
  overflow: visible;
  clip: auto;
}

.markdown-v2 ::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}